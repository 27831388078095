import React from 'react'
import './Media.scss'

const Media = ({ data }) => {
    console.log(data)
    data = data['section__media'];
    return (
        <div className='media container flex flex-col items-center'>
            {data.media_links.map((item, index) =>
                <a href={item.media_link.url} className="link" target='_blank' key={index}>{item.media_link.title}</a>
            )}
        </div>
    )
}

export default Media