import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import colors from '../../config/colors';

const CenterLoader = () => {
  return (
      <section className='loading flex justify-center py-8'>
        <ClipLoader color={colors.primary} />
      </section>
  )
}

export default CenterLoader