import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Button from '../../components/Button/Button'
import menuIcon from '../../assets/icons/menu-icon.svg';
import CloseMenu from '../../assets/icons/close-modal.svg';
import './Header.scss'

const Header = ({ data }) => {
    AOS.init();

    function openMenu() {
        document.querySelector('#menu').classList.toggle('active');
    }

    function closeMenu() {
        document.querySelector('#menu').classList.remove('active');
    }

    return (
        <header className='site-header' data-aos="fade-down" data-aos-delay="50">
            <div className="site-header__wrap container flex justify-between items-center">

                {data.logo && <img className='logo' width={317} src={data.logo.url} alt={data.logo.alt} />}

                <nav id="menu" className='menu'>

                    <img src={CloseMenu} className="close" id="closeMenu" onClick={() => closeMenu()} />

                    {data.navigation &&
                        <ul>
                            {data.navigation.map((item, index) => (
                                <li key={index}><a href={item.link.url} onClick={() => closeMenu()}>{item.link.title}</a></li>
                            ))}
                        </ul>
                    }
                    {data.cta &&
                        <Button href={data.cta.url} className="ml-12">
                            {data.cta.title}
                        </Button>
                    }
                </nav>

                <img src={menuIcon} alt="" className="mobile-menu" id='toggleMenu' onClick={() => openMenu()} />
            </div>
        </header>
    )
}

export default Header