import React from 'react'
import { useState, useRef, useEffect } from 'react'
import './SliderSurfaceTable.scss'
import SlideToggle from '../../assets/icons/slide-toggle.svg';

const SliderSurfaceTable = ({ data }) => {
    const [slideContent, setSlideContent] = useState(0);
    const slideFade = useRef();
    const slideSlide = useRef();


    const setSlideIndex = (e) => {
        if (e === true) {
            if (slideContent < data.length - 1) {
                setSlideContent(slideContent + 1);
            } else {
                setSlideContent(0);
            }
        } else {
            if (slideContent > 0) {
                setSlideContent(slideContent - 1);
            }
        }

    }

    useEffect(() => {
        slideFade.current.animate({ opacity: [0, 1] }, 800)

        slideSlide.current.animate([
            { transform: 'translateX(-100%)' },
            { transform: 'translateX(0)' },
        ], 1000)
    }, [slideContent]);

    return (
        <div className='slider-surface-table container--big mx-auto'>
            <div className="slider container">
                {data.map((slide, index) =>
                    <>
                        {slideContent === index &&
                            <div className="slide-fw flex flex-row items-center justify-between" key={index}>
                                <div className="col col-image" ref={slideSlide}>
                                    <img src={slide.image.url} alt={slide.image.alt} />
                                </div>
                                <div className="col col-table flex flex-col" ref={slideFade}>
                                    <h4 className="title">{slide.col_info.title}</h4>
                                    {slide.col_info.rows.map((tableRow, index) =>
                                        <div className="row flex flex-row justify-between items-center" key={index}>
                                            <h6>{tableRow.title}</h6>
                                            <span>{tableRow.amount} m²</span>
                                        </div>
                                    )}

                                    <div className="slider-nav flex flex-row items-center justify-between">
                                        <span className="slide-back" id={index === 0 && 'deactive'} onClick={() => setSlideIndex(false)}>vorige</span>
                                        <span className="next-slide flex flex-row items-center" onClick={() => setSlideIndex(true)} >
                                            <span>Volgende</span>
                                            <img src={SlideToggle} alt="" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    )
}

export default SliderSurfaceTable