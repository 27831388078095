import { useEffect, useState } from "react";
import CenterLoader from "./components/CenterLoader/CenterLoader";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import ViewPort from "./components/Viewport/ViewPort";
import { FetchApiData } from "./middleware/fetchApi";
import Page from "./views/Page";


function App() {
	const optionsAcf = FetchApiData(process.env.REACT_APP_BASEURL + 'wp-json/acf/v3/options/options');
	const [scrolled, setScrolled] = useState(false)
	const handleScroll = () => {
		if (window.pageYOffset >= 10 && !scrolled) {
			setScrolled(true);
		}
	};
	
	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		setTimeout(() => {
			setScrolled(true);
		}, 2700);
	
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [handleScroll]);

    return (
		<div className="App">
			{optionsAcf && <Header data={optionsAcf.acf.header} />}
			{optionsAcf &&
				<main>
					{/* Load only first viewport */}
					{optionsAcf.acf.viewport && <ViewPort data={optionsAcf.acf.viewport}/>}

					{/* Load the rest after 150px scroll */}
					{scrolled ?
						<Page />
						:
						<CenterLoader/>
					}
				</main>
			}
			{optionsAcf && <Footer data={optionsAcf.acf.footer} />}
		</div>
    );
}

export default App;
