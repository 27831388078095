import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import './LabelText.scss'

const LabelText = ({ data }) => {
    AOS.init();

    return (
        <section className="label-text" id={data.anchor_id}>
            <div className="label-text__wrap container container--small">
                {data.label && <p className="label-text__label" data-aos="fade-left" data-aos-duration="650" dangerouslySetInnerHTML={{ __html: data.label }} />}
                {data.text && <p className="label-text__text" data-aos="fade-in" data-aos-delay="150" data-aos-duration="650" dangerouslySetInnerHTML={{ __html: data.text }} />}
            </div>
        </section>
    )
}

export default LabelText