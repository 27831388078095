import React from 'react'
import { useState, useRef, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import './SliderAndPopup.scss'
import SlideToggle from '../../assets/icons/slide-toggle.svg';
import CloseModal from '../../assets/icons/close-modal.svg';

const SliderAndPopup = ({ data }) => {
    data = data['section__slider-and-popup'];

    AOS.init();

    const [slideContent, setSlideContent] = useState(0);
    const [modal, setModalState] = useState(false);
    const slideFade = useRef();
    const modalFade = useRef();

    const setSlideIndex = () => {
        if (slideContent < data.slider.length - 1) {
            setSlideContent(slideContent + 1);

        } else {
            setSlideContent(0);
        }
    }

    useEffect(() => {
        slideFade.current.animate({ opacity: [0, 1] }, 500)
    }, [slideContent]);

    useEffect(() => {
        if (modal == true) modalFade.current.animate({ opacity: [0, 1] }, 500)
    }, [modal]);

    console.log(data.popup_more_info)

    return (
        <>
            <div className='slider-and-popup container--big mx-auto'>
                <div className="container slider flex flex-row items-center">
                    <div className="col-left intro-text flex flex-col justify-center items-end" data-aos="fade-right" data-aos-delay="50" data-aos-duration="850">
                        <div className="text">
                            <p>{data.col_text.intro_text}</p>
                            {(data.popup_more_info.col_text_left.length > 0) &&
                                <span className="toggle-popup" id='openPopup' onClick={() => setModalState(true)}>lees meer</span>
                            }
                        </div>
                        <span className="next-slide flex flex-row items-center" onClick={setSlideIndex} >
                            <span>Volgende</span>
                            <img src={SlideToggle} alt="" />
                        </span>
                    </div>
                    <div className="col-right slider" data-aos="fade-in" data-aos-delay="150" data-aos-duration="850">
                        {data.slider.map((slide, index) =>
                            <>
                                {slideContent == index &&
                                    <div className="slide" key={index} ref={slideFade}>
                                        <img src={slide.slide.url} alt={slide.slide.alt} />
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>


            {modal == true &&
                <div className="modal-info flex items-center" key='modalTarget' ref={modalFade} onClick={() => setModalState(false)}>
                    <div className="modal-inner container mx-auto flex flex-row align-middle">
                        {data.popup_more_info.col_text_left &&
                            <div className="col col-text" dangerouslySetInnerHTML={{ __html: data.popup_more_info.col_text_left }}></div>
                        }
                        {data.popup_more_info.col_text_right &&
                            <div className="col col-text" dangerouslySetInnerHTML={{ __html: data.popup_more_info.col_text_right }}></div>
                        }
                        {data.popup_more_info.col_image &&
                            <div className="col col-image">
                                <img src={data.popup_more_info.col_image.url} alt={data.popup_more_info.col_image.alt} />
                            </div>
                        }

                        <img src={CloseModal} alt="" className="close-modal" id="closeModal" />
                    </div>
                </div>
            }
        </>
    )
}

export default SliderAndPopup