import React from 'react'
import FlexibleLayout from '../layouts/FlexibleLayout/FlexibleLayout'
import { FetchApiData } from '../middleware/fetchApi'
import CenterLoader from '../components/CenterLoader/CenterLoader';


const Page = () => {
    let page = FetchApiData(process.env.REACT_APP_BASEURL + 'wp-json/wp/v2/pages?slug=home')
    return (
        <>
            {page ?
                <FlexibleLayout data={page[0].acf} />
                :
                <CenterLoader />


            }
        </>
    )
}

export default Page