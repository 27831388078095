import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './DuoImage.scss'

const DuoImage = ({ data }) => {
    data = data['section__duo-img']

    AOS.init()

    return (
        <div className='duo-image container--big mx-auto flex flex-row justify-between items-center' id={data.anchor_id}>
            <img src={data['image-left'].url} alt={data['image-left'].alt} data-aos="fade-right" data-aos-delay="20" data-aos-duration="850" />
            <img src={data['image-right'].url} alt={data['image-right'].alt} data-aos="fade-left" data-aos-delay="20" data-aos-duration="850" />
        </div>
    )
}

export default DuoImage