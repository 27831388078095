import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Hero.scss'
import SlideToggle from '../../assets/icons/slide-toggle.svg';

const Hero = ({ data }) => {
    AOS.init();

    return (
        <section className='hero'>
            <div className="hero__wrap container--big mx-auto">
                <a href='#LOCATIEGEGEVENS' className="scroll-down-trigger flex flex-row items-center">
                    <span>scroll down</span>
                    <img src={SlideToggle} alt="" />
                </a>

                {data.section__hero.title && <h1 data-aos="fade-in" data-aos-delay="20" data-aos-duration="850" className='hero__title container container--small' dangerouslySetInnerHTML={{ __html: data.section__hero.title }} />}
                {data.section__hero.image && <img className='hero__image' src={data.section__hero.image.url} alt={data.section__hero.image.alt} data-aos="fade-up" data-aos-delay="100" data-aos-duration="650" />}
            </div>
        </section>
    )
}

export default Hero