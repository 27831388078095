import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './GridImages.scss'

const GridImages = ({ data }) => {
    data = data['section__grid-images'];

    AOS.init()

    return (
        <div className='grid-images container'>
            <div className="wrap flex flex-row justify-between">
                <div className="col col-left" data-aos="fade-right" data-aos-delay="20" data-aos-duration="850">
                    <img src={data.image_1.url} alt={data.image_1.alt} />
                </div>
                <div className="col col-right">
                    <img src={data.image_2.url} alt={data.image_2.alt} data-aos="fade-left" data-aos-delay="40" data-aos-duration="850" />
                    <img src={data.image_3.url} alt={data.image_3.alt} data-aos="fade-up" data-aos-delay="60" data-aos-duration="850" />
                </div>
            </div>
        </div>
    )
}

export default GridImages